var indexColumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '20%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: 'ICON',
  dataIndex: 'icon',
  key: 'icon',
  width: '20%',
  scopedSlots: {
    customRender: 'icon'
  }
}, {
  title: '工具标题',
  dataIndex: 'name',
  key: 'name',
  width: '20%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '网站链接',
  dataIndex: 'websiteurl',
  key: 'websiteurl',
  width: '10%',
  scopedSlots: {
    customRender: 'websiteurl'
  }
}, {
  title: '所属分类',
  dataIndex: 'categories',
  key: 'categories',
  width: '10%',
  scopedSlots: {
    customRender: 'categories'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '10%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  key: 'action',
  width: '10%',
  scopedSlots: {
    customRender: 'action'
  }
}];
var categoryColumns = [{
  title: '分类ID',
  dataIndex: 'id',
  key: 'id',
  width: '40%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '10%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: '分类名称',
  dataIndex: 'name',
  key: 'name',
  width: '30%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: '20%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { indexColumns, categoryColumns };