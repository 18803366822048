var categorySearchKeys = [{
  key: "create_time",
  label: "创建时间",
  placeholder: ['开始时间', '截止时间'],
  required: false,
  rules: [],
  dates: true
}, {
  key: "name",
  label: "分类名称",
  placeholder: "分类名称",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var indexSearchKeys = [{
  key: "categoryname",
  label: "所属分类",
  placeholder: "请选择分类名称",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "name",
  label: "工具标题",
  placeholder: "请输入工具标题",
  required: false,
  rules: [],
  input: true
}, {
  key: "status",
  label: "状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { indexSearchKeys, categorySearchKeys };